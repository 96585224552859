<template>
  <div class="talent">
    <container>
      <div class="search-panel">
        <div class="input-wrap">
          <el-input />
          <el-button style="margin-left: 15px">搜索</el-button>

          <span class="more-btn" @click="showSearchFilter = !showSearchFilter">
            更多选项

            <i class="el-icon-arrow-down"></i>
          </span>
        </div>

        <div class="more-search-filter" v-show="showSearchFilter">
          <div class="search-filter">
            <div class="filter-row">
              <div class="row-label">历史记录</div>
              <div class="row-content">
                <div class="item">web前端·深圳</div>
              </div>
              <span class="del-btn">
                删除
                <i class="el-icon-delete"></i>
              </span>
            </div>
          </div>

          <div class="search-filter">
            <span class="del-btn">
              清空
              <i class="el-icon-delete"></i>
            </span>

            <div class="filter-row">
              <div class="row-label">热门词</div>
              <div class="row-content">
                <div class="hot">单片机开发</div>
                <div class="hot">电路设计</div>
                <div class="hot">新媒体运营</div>
                <div class="hot">Web端</div>
                <div class="hot">Windows</div>
                <div class="hot">FPGA开发</div>
              </div>
            </div>

            <div class="filter-row">
              <div class="row-label">学历</div>
              <div class="row-content">
                <div class="item">不限</div>
                <div class="item">本科及以上</div>
                <div class="item">硕士及以上</div>
                <div class="item">博士</div>
                <div class="item">自定义</div>
                <div class="item">
                  <el-select
                    size="small"
                    style="width: 110px"
                    v-model="value"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="item">到</div>
                <div class="item">
                  <el-select
                    size="small"
                    style="width: 110px"
                    v-model="value"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>

                <div class="item">|</div>
                <div class="item">
                  <el-checkbox v-model="checked">统招本科</el-checkbox>
                </div>

                <div class="item">
                  <el-checkbox v-model="checked">双一流</el-checkbox>
                </div>

                <div class="item">
                  <el-checkbox v-model="checked">985</el-checkbox>
                </div>

                <div class="item">
                  <el-checkbox v-model="checked">211</el-checkbox>
                </div>
                <div class="item">
                  <el-checkbox v-model="checked">留学生</el-checkbox>
                </div>
              </div>
            </div>

            <div class="filter-row">
              <div class="row-label">经验</div>
              <div class="row-content">
                <div class="item">不限</div>
                <div class="item">在校/应届</div>
                <div class="item">1-3年</div>
                <div class="item">3-5年</div>
                <div class="item">5-10年</div>
                <div class="item">自定义</div>

                <div class="item">
                  <el-select
                    size="small"
                    style="width: 110px"
                    v-model="value"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="item">至</div>
                <div class="item">
                  <el-select
                    size="small"
                    style="width: 110px"
                    v-model="value"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>

            <!-- <div class="filter-row">
              <div class="row-label">求职状态(多选)</div>
              <div class="row-content">
                <div class="hot">单片机开发</div>
                <div class="hot">电路设计</div>
                <div class="hot">新媒体运营</div>
                <div class="hot">Web端</div>
                <div class="hot">Windows</div>
                <div class="hot">FPGA开发</div>
              </div>
            </div> -->
          </div>

          <div class="combine-filter">
            <div class="filter-item">
              <div class="filter-title">求职状态（多选）</div>
              <div>
                <el-select
                  size="small"
                  style="width: 110px"
                  v-model="value"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="filter-item">
              <div class="filter-title">牛人活跃度</div>
              <div>
                <el-select
                  size="small"
                  style="width: 110px"
                  v-model="value"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="filter-item">
              <div class="filter-title">性别</div>
              <div>
                <el-select
                  size="small"
                  style="width: 110px"
                  v-model="value"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="filter-item">
              <div class="filter-title">年龄要求</div>
              <div>
                <el-select
                  size="small"
                  style="width: 110px"
                  v-model="value"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <span> 至 </span>

                <el-select
                  size="small"
                  style="width: 110px"
                  v-model="value"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="filter-item">
              <div class="filter-title">期望薪资</div>
              <div>
                <el-select
                  size="small"
                  style="width: 110px"
                  v-model="value"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <span> 至 </span>

                <el-select
                  size="small"
                  style="width: 110px"
                  v-model="value"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </container>

    <container>
      <div class="filter-wrap">
        <div class="filter">
          <div class="left">
            <el-button-group>
              <el-button size="medium">推荐牛人</el-button>
              <el-button size="medium">精选牛人</el-button>
              <el-button size="medium">新牛人</el-button>
            </el-button-group>

            <el-button-group>
              <el-button size="medium">看过我</el-button>
              <el-button size="medium">对我感兴趣</el-button>
            </el-button-group>

            <el-button-group>
              <el-button size="medium">我看过</el-button>
            </el-button-group>

            <el-button-group>
              <el-button size="medium">同时推荐</el-button>
            </el-button-group>

            <el-button-group>
              <el-button size="medium">收藏牛人</el-button>
            </el-button-group>
          </div>
          <div class="right">
            <el-dropdown>
              <span class="el-dropdown-link">
                深圳<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>黄金糕</el-dropdown-item>
                <el-dropdown-item>狮子头</el-dropdown-item>
                <el-dropdown-item>螺蛳粉</el-dropdown-item>
                <el-dropdown-item disabled>双皮奶</el-dropdown-item>
                <el-dropdown-item divided>蚵仔煎</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

            <span style="margin-left: 10px">
              <i class="el-icon-goblet-full"></i>
              筛选
            </span>
          </div>
        </div>
      </div>
    </container>

    <container>
      <div class="more-filter">
        <div class="left">
          <div class="avatar">
            <el-avatar
              src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
            ></el-avatar>
          </div>
          <div class="desc">
            <div class="title">期望候选人掌握哪些技能或工具？</div>
            <div class="desc-text">我们将根据您的选择，为你优化推荐求职者</div>
          </div>
        </div>
        <div class="right">
          <el-button size="medium" style="width: 100px">C</el-button>
          <el-button size="medium" style="width: 100px">C++</el-button>
          <el-button size="medium" style="width: 100px">+更多选项</el-button>
        </div>
      </div>
    </container>

    <container>
      <div class="talent-list">
        <div
          class="talent-box-wrap"
          v-for="(i, index) in talentList"
          :key="i.id"
          :ref="`talent-box-${index}`"
        >
          <div class="talent-box">
            <div class="left">
              <div class="avatar-price">
                <div class="avatar-wrap">
                  <el-avatar
                    :size="60"
                    src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
                  ></el-avatar>
                </div>
                <div class="name">马保国</div>
                <div class="online-tag">本科</div>
                <div class="price">9-12K</div>
              </div>
              <div class="basic-info">
                <!-- <div class="name">
                马保国
                <span>刚刚活跃</span>
              </div> -->
                <div class="tags">
                  <span>28岁</span>
                  <span>4年</span>

                  <span>离职-随时到岗</span>

                  <span style="color: #999">(刚刚活跃)</span>
                </div>
                <div class="job-wish">求职期望：深圳 · 嵌入式</div>
                <div class="talent-desc">
                  精通C语言，数据结构、51单片机，SMT32单片机；熟练FATFS文件系统移植及使用。
                  精通C语言，数据结构、51单片机，SMT32单片机；熟练FATFS文件系统移植及使用。
                  精通C语言，数据结构、51单片机，SMT32单片机；熟练FATFS文件系统移植及使用。
                  精通C语言，数据结构、51单片机，SMT32单片机；熟练FATFS文件系统移植及使用。
                </div>
              </div>
            </div>

            <div class="center">
              <div class="time-line-wrap">
                <div class="time-line" v-for="i in 4" :key="i">
                  <div class="label">2020.08-2021.07</div>
                  <div class="line-content">
                    小熊电器股份有限公司深圳研发中心·软件
                  </div>

                  <span class="dot" v-show="i === 1 || i === 3"></span>
                </div>
              </div>

              <div class="detail-btns">
                <el-link :underline="false"> 查看简历 </el-link>
              </div>
            </div>

            <div class="right">
              <el-button
                v-if="!i.showMsg"
                style="width: 100px"
                size="medium"
                @click="openMsg(index)"
                >打招呼</el-button
              >

              <el-button
                v-if="i.showMsg"
                style="width: 100px"
                size="medium"
                @click="i.showMsg = false"
              >
                关闭对话
              </el-button>
            </div>

            <div class="collect-btns">
              <el-link :underline="false">初选</el-link>
              <el-link style="margin-left: 10px" :underline="false"
                >精选</el-link
              >
            </div>
          </div>

          <transition name="el-zoom-in-top">
            <message v-if="i.showMsg" />
          </transition>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
import Message from "../../../components/ori-com/Message.vue";
import Container from "./Container.vue";
export default {
  components: {
    Container,
    Message,
  },

  data() {
    return {
      talentList: new Array(8).fill(0).map((i, idx) => ({
        id: idx + i,
        showMsg: false,
      })),

      showSearchFilter: false,

      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",

      checked: "",
    };
  },

  methods: {
    openMsg(idx) {
      this.talentList.forEach((i, index) => {
        i.showMsg = false;

        if (idx === index) {
          i.showMsg = true;
        }
      });

      // const ref = this.$refs[`talent-box-${idx}`];

      // if (ref && ref[0]) {
      //   const height = ref[0].offsetTop;

      //   window.scrollTo({
      //     top: height - 80,
      //     behavior: "smooth",
      //   });
      //   console.log("%O", ref[0]);
      // }

      this.talentList = [...this.talentList];
    },
  },
};
</script>

<style lang="scss" scoped>
.talent {
  .search-panel {
    // margin-bottom: 15px;
    padding: 15px 0;
    padding-bottom: 0;
    background: #fff;

    .input-wrap {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .more-btn {
        cursor: pointer;
        font-size: 14px;
        position: absolute;
        right: 30px;
      }

      ::v-deep .el-input {
        width: 60%;
      }
    }

    .combine-filter {
      margin: 0 20px;
      font-size: 14px;

      .filter-item {
        display: inline-block;
        & + .filter-item {
          margin-left: 20px;
        }

        .filter-title {
          margin-bottom: 10px;
        }
      }
    }

    .search-filter {
      margin: 0 20px;
      position: relative;
      font-size: 14px;

      & + .filter {
        border-top: 1px solid #ededed;
      }

      .del-btn {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 10px;
        color: #999;
      }

      .filter-row {
        position: relative;
        padding: 15px 0;
        display: flex;
        align-items: center;

        .row-label {
          width: 100px;
          flex: 0 0 auto;
        }

        .row-content {
          flex: 1 1 auto;

          padding-right: 60px;

          display: flex;
          flex-wrap: wrap;
          align-items: center;

          .item {
            color: #666;

            & + .item {
              margin-left: 15px;
            }
          }
          .hot {
            display: inline-block;
            background: #f8f8f8;
            padding: 4px 10px;
            border-radius: 4px;
            line-height: 1;
            vertical-align: middle;
            color: #333;

            & + .hot {
              margin-left: 15px;
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.talent {
  padding: 30px 0;

  .filter-wrap {
    padding: 15px 20px;
    padding-top: 20px;
    background: #fff;
    margin-bottom: 15px;
  }

  .filter {
    // margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      ::v-deep .el-button-group {
        & + .el-button-group {
          margin-left: 15px;
        }
      }
    }
  }

  .more-filter {
    padding: 15px 25px;
    margin-bottom: 15px;
    background: #fff;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      .desc {
        max-width: 320px;
        margin-left: 15px;
        overflow: hidden;
        text-overflow: ellipsis;

        .title {
          font-weight: bold;
        }

        .desc-text {
          margin-top: 4px;
          font-size: 12px;
          color: #666;
        }
      }
    }
  }

  .talent-box-wrap + .talent-box-wrap {
    margin-top: 15px;
  }

  .talent-box {
    position: relative;
    overflow: hidden;
    padding: 20px 30px;
    background: #fff;

    display: flex;

    justify-content: space-between;
    // align-items: flex-start;

    .collect-btns {
      position: absolute;
      right: 30px;
    }

    & + .talent-box {
      margin-top: 15px;
    }

    .left,
    .center {
      flex: 0 0 auto;
      width: 400px;
    }

    .left {
      display: flex;
      align-items: flex-start;

      .avatar-price {
        flex: 0 0 auto;
        margin-right: 30px;
        text-align: center;

        .price {
          font-size: 14px;
          margin-top: 8px;
          padding: 0 10px;
          border-radius: 10px;
          background: #ff4e20;
          color: #fff;
        }

        .online-tag {
          font-size: 12px;
          color: #666;
        }
      }

      .basic-info {
        width: 100%;
        .name {
          font-size: 18px;
          span {
            font-size: 16px;
            color: #666;
            margin-left: 8px;
          }

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .tags {
          font-size: 14px;
          margin: 8px 0;
          span + span {
            margin-left: 8px;
            padding-left: 8px;
            border-left: 1px solid #ededed;
          }
        }

        .job-wish {
          font-size: 14px;
          color: #666;
          width: 100%;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .talent-desc {
          margin-top: 8px;
          font-size: 14px;
          color: #666;

          line-height: 18px;
          max-height: 54px;

          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }
      }
    }

    .center {
      // display: flex;
      // align-items: center;

      .time-line-wrap {
        padding-left: 20px;
        padding-bottom: 10px;
        border-left: 1px dashed #409eff;

        .time-line {
          position: relative;
          display: flex;

          font-size: 14px;

          .label {
            flex: 0 0 auto;
            width: 120px;
            color: #666;
            // fontsi
          }

          .line-content {
            width: 290px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .dot {
            position: absolute;
            width: 14px;
            height: 14px;
            background: #409eff;
            border-radius: 50%;

            left: -28px;
            // top: 1px;
          }
        }
      }

      .detail-btns {
        margin-top: 10px;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
}
</style>